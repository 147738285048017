'use client'

import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/navigation'
import Link from 'next/link'
import Image from 'next/image'
import { PiArrowLineDownLeft } from 'react-icons/pi'
import { PiArrowLineUpLeftLight } from 'react-icons/pi'
import { CiLocationOn } from 'react-icons/ci'

import { SlArrowDown, SlArrowUp } from 'react-icons/sl'

import { Row, Col, Container } from 'react-bootstrap'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'

// import required modules
import { Autoplay, FreeMode } from 'swiper/modules'

import NewsOne from '@/assets/home/news/news-1.webp'
import NewsTwo from '@/assets/home/news/news-2.webp'
import NewsThree from '@/assets/home/news/news-three.webp'
import NewsFour from '@/assets/home/news/news-four.webp'
import { capitalizeFirstLetter } from '@/utils'

function MarketNews({ prices, news }) {
	const router = useRouter()
	const [selectType, setSelectType] = useState(0)
	const [loadMore, setLoadMore] = useState(true)

	const images = [NewsOne, NewsTwo, NewsThree, NewsFour]

	const handleResize = () => {
		if (window.innerWidth < 767.98) {
			setSelectType(1)
		} else {
			setSelectType(0)
		}
	}

	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.addEventListener('resize', handleResize)
		}
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const getTimeDiff = (price_date) => {
		return `${new Date(price_date).toLocaleDateString('en-GB')}`
	}

	const getFormattedDate = (date) => {
		return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
	}

	const newsClickHandler = (e, value) => {
		if (value?.source_link.includes('http')) {
			window.open(value?.source_link)
		} else if (value?.slug) {
			router.push(`/blogs/${value?.slug}`)
		}
	}

	return (
		<Container fluid='lx' id='news-market-prices'>
			<div className='market-news-container'>
				<div>
					<h1 className='text-center heading'>Insights</h1>
					<p className='paragraph2'>Keep up to date with Industry News and Commodity Prices.</p>
				</div>

				<div className='d-flex gap-2 mt-4 justify-content-lg-start justify-content-start justify-content-center'>
					<button
						className={`btn action-button ${selectType === 0 ? 'btn-primary border-0' : 'border-2 border-black'} rounded-3 px-3`}
						onClick={() => {
							setSelectType(0)
						}}
					>
						News
					</button>
					<button
						className={`btn action-button ${selectType === 1 ? 'btn-primary border-0' : 'border-2 border-black'} rounded-3 px-3`}
						onClick={() => {
							setSelectType(1)
						}}
					>
						Prices
					</button>
				</div>

				<div className='d-flex gap-4 mt-5 justify-content-center swiper-mobile'>
					<Swiper
						spaceBetween={25}
						slidesOffsetAfter={0}
						autoplay={{
							delay: 1,
							disableOnInteraction: false,
						}}
						speed={6000}
						loop={true}
						breakpoints={{
							0: {
								slidesPerView: 1,
							},
							400: {
								slidesPerView: 1,
							},
							639: {
								slidesPerView: 3,
							},
							865: {
								slidesPerView: 4,
							},
							1000: {
								slidesPerView: 6,
							},
							1500: {
								slidesPerView: 6,
							},
							1700: {
								slidesPerView: 7,
							},
						}}
						modules={[Autoplay, FreeMode]}
						className='mySwiper'
					>
						{prices.map((item, i) => {
							const change = (
								((item?.prices?.[0] - (item?.prices?.[1] || item?.prices?.[0])) / (item?.prices?.[1] || item?.prices?.[0])) *
								100
							).toFixed(2)

							return (
								<SwiperSlide key={`${i}-market`}>
									<div className='news-card card-selected'>
										<div className='d-flex w-100 justify-content-between align-items-center'>
											<h3>{item?.product_name}</h3>
											<p className='muted-text align-items-center'>{capitalizeFirstLetter(item?.primary_or_secondary)}</p>
										</div>
										<span>{item?.grade}</span>
										<div className='box'>
											<p>{`${(item?.price_per_ton / 1000).toFixed(1)} / Kg`}</p>
											<span className={'tag ' + (change <= 0 ? 'tag-red' : '')}>
												{change <= 0 ? (
													<PiArrowLineDownLeft size='11' color='red' />
												) : (
													<PiArrowLineUpLeftLight size='11' color='green' />
												)}
												{change} %
											</span>
										</div>
										<span className='time-ago'>{getTimeDiff(item?.price_date)}</span>
										<div className='d-flex gap-1'>
											<CiLocationOn size='14' color='#292D32' />
											<p className='muted-text fw-bold'>{item?.location}</p>
										</div>
									</div>
								</SwiperSlide>
							)
						})}
					</Swiper>
				</div>

				{selectType === 0 && (
					<div className='market-section-2'>
						<Row className='g-4'>
							{/* news content */}
							{news.map((item, i) => (
								<div className='col-12 col-lg-4 d-flex my-2' key={i}>
									<div className='card border-0 h-100 w-100 d-flex cursor-pointer' onClick={(e) => newsClickHandler(e, item)}>
										<figure className='card-img-top m-0 overflow-hidden bsb-overlay-hover d-flex position-relative rounded-top-4'>
											<Image
												src={images[i % 4]}
												alt={item?.title}
												className='img-fluid bsb-scale bsb-hover-scale-up w-100 h-100'
												width={0}
												height={0}
											/>
											<div className='position-absolute bottom-0 end-0 bg-white news-date'>
												<span className='mx-2 fs-8 card-span text-dark'>{getFormattedDate(new Date(item?.date))}</span>
											</div>
										</figure>
										<div className='card-body border-0 bg-white px-3 py-3 shadow d-flex flex-column gap-3'>
											<div className='label'>
												<h2 className='entry-title mb-0 text-1'>{item?.title}</h2>
											</div>
											<div className='pt-0'>
												<p className='card-text entry-summary text-secondary card-span subtitle'>{item?.summary}</p>
											</div>
										</div>
										<div className='card-footer border-0 border-top bg-white p-2 shadow rounded-bottom-4'>
											<span className='text-decoration-none d-flex gap-1 px-2 align-items-end justify-content-between'>
												<span className='card-span text-danger'>{/*item?.tags?.map((item) => item).join(', ')*/}</span>
												<span className='card-span'>
													<svg xmlns='http://www.w3.org/2000/svg' width={6} height={7} viewBox='0 0 6 7' fill='none'>
														<circle cx={3} cy='3.12207' r={3} fill='#B2B1B4' />
													</svg>{' '}
													{item?.source}
												</span>
											</span>
										</div>
									</div>
								</div>
							))}
						</Row>

						<div className='d-flex justify-content-center mt-5'>
							<Link href='/blogs#news'>
								<button className='btn-view-more view-more border shadow-sm'>
									View More
									<SlArrowDown size='18' />
								</button>
							</Link>
						</div>
					</div>
				)}

				{selectType === 1 && (
					<Row className='mt-3'>
						{prices.map((item, i) => {
							if (loadMore && i > 5) {
								return
							}
							const change = (
								((item?.prices?.[0] - (item?.prices?.[1] || item?.prices?.[0])) / (item?.prices?.[1] || item?.prices?.[0])) *
								100
							).toFixed(2)
							return (
								<Col xs={6} key={`${i}-market`} className='g-3 d-grid'>
									<div className='news-card card-selected'>
										<div className='d-flex w-100 justify-content-between align-items-center'>
											<h3>{item?.product_name}</h3>
											<p className='muted-text align-items-center'>{capitalizeFirstLetter(item?.primary_or_secondary)}</p>
										</div>
										<span>{item?.grade || '-'}</span>
										<div className='box'>
											<p>{`${(item?.price_per_ton / 1000).toFixed(1)} / Kg`}</p>
											<span className={'tag ' + (change <= 0 ? 'tag-red' : '')}>
												{change <= 0 ? (
													<PiArrowLineDownLeft size='11' color='red' />
												) : (
													<PiArrowLineUpLeftLight size='11' color='green' />
												)}
												{change} %
											</span>
										</div>
										<span>{getTimeDiff(item?.price_date)}</span>
										<div className='d-flex gap-1'>
											<CiLocationOn size='14' color='#292D32' />
											<p className='muted-text'>{item?.location}</p>
										</div>
									</div>
								</Col>
							)
						})}
						<div className='d-flex justify-content-center mt-5'>
							<button
								className='btn-view-more view-more'
								onClick={() => {
									setLoadMore(!loadMore)
								}}
							>
								{loadMore ? (
									<>
										Load More
										<SlArrowDown size='18' />
									</>
								) : (
									<>
										Load Less
										<SlArrowUp size='18' />
									</>
								)}
							</button>
						</div>
					</Row>
				)}
			</div>
		</Container>
	)
}

export default MarketNews
