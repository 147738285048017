'use client'

import { FaRegWindowClose } from 'react-icons/fa'
import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import Modal from 'react-bootstrap/Modal'
const VideoPlayer = dynamic(() => import('@/components/VideoPlayer'), {
	ssr: false,
})

const VideoModal = ({ setShowVideo, showVideo }) => {
	const [show, setShow] = useState(showVideo)
	const handleClose = () => setShow(false)

	const _handleClose = () => {
		handleClose()
		setShowVideo(!showVideo)
	}
	return (
		<>
			<Modal show={show} size='xl' centered onHide={_handleClose} animation={false} className='border-0'>
				<Modal.Header>
					<FaRegWindowClose
						size='32'
						color='#FFF'
						className='cursor-pointer'
						onClick={() => {
							_handleClose()
						}}
					/>
				</Modal.Header>
				<Modal.Body>
					<div className='modal-video-player'>
						<VideoPlayer videoId={'N57MNqPoHSQ'} />
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default VideoModal
