'use client'

import React, { useEffect, useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import Image from 'next/image'

import GAIcon from '@/assets/brands/GA_150x50.webp'
import JSLIcon from '@/assets/brands/JSL_150x50.webp'
import DBLIcon from '@/assets/brands/DBL150x50.webp'
import TataIcon from '@/assets/brands/Tata_150x50.webp'

import Hero from '@/assets/banner/hero.webp'
import HeroPop from '@/assets/banner/hero_pop.gif'
import HeroFab from '@/assets/banner/hero_fab.gif'
import HeroRays from '@/assets/banner/blue_rays.gif'
import HeroRaysLaptop from '@/assets/banner/rays_laptop.webp'

import MobileImg from '@/assets/banner/ray_bg_mobile.webp'
import MobileRays from '@/assets/banner/mobile_ray.gif'
import MobilePopUp from '@/assets/banner/mobile_pop_up.gif'
import MobileFab from '@/assets/banner/mobile_fabrication.gif'
import MobileGroup from '@/assets/banner/mobile_group.webp'

import styles from '../page.module.scss'
import strings from '@/constants/strings'
import Search from '@/components/Search'

const rotatingTexts = ['Metal Fabrication', 'Metal Recycling', 'Metal Sourcing', 'Financing Solutions']

function Banner({ categories = [] }) {
	const [rotateIndex, setRotateIndex] = useState(0)

	useEffect(() => {
		setTimeout(() => {
			setRotateIndex((rotateIndex + 1) % 4)
		}, 1500)
	}, [rotateIndex])

	return (
		<Container fluid>
			<Row className='row-banner'>
				<Col className='d-flex justify-content-center' xs={12} md={12} lg={4}>
					<div className='banner-inner gy-3'>
						<div className='display-laptop-view'>
							<div>
								<p>
									{`India's Largest`} <br /> {`Managed Marketplace for`}
								</p>
								<h1 className='banner-title'>{rotatingTexts[rotateIndex]}</h1>
								<p className='banner-subheading-colored'>{strings('home_banner_sub_heading')}</p>
							</div>
							<div className='pt-3'>
								<Search data={categories} />
							</div>
							<div>
								<Row className='position-relative g-0 mt-3'>
									<Col md={12} xs={12} className={`d-flex flex-column justify-content-around g-3 ps-0 ${styles.companyIcon}`}>
										<div className='trusted-brands'>
											<span className='banner-italic-text'>
												Trusted by <b>2500+</b> SMEs & Enterprises
											</span>
										</div>
										<div className={`md-12 justify-content-around brand-gap scroll-tab ${styles.homeBannerBrand}`}>
											<Image src={GAIcon} alt='ga infra' />
											<Image src={JSLIcon} alt='jsl' />
											<Image src={DBLIcon} alt='dbl' />
											<Image src={TataIcon} alt='tata' />
										</div>
									</Col>
								</Row>
							</div>
						</div>
					</div>
				</Col>
				<Col xs={12} lg={8} md={12}>
					<div className='display-mobile-view'>
						<div>
							<p className='no-wrap'>{strings('home_banner_heading')}</p>
							<h1 className='banner-title'>{rotatingTexts[rotateIndex]}</h1>
							<p className='banner-subheading-colored'>{strings('home_banner_sub_heading')}</p>
						</div>
						<div>
							<Search data={categories} />
						</div>
					</div>
					<div className='display-laptop-view banner-layer'>
						<div className='position-relative'>
							<Image className='position-absolute shield' src={HeroRaysLaptop} alt='Picture of the author' />

							<Image className='position-absolute shield' src={Hero} alt='Picture of the author' />
							<Image className='position-absolute  shield' src={HeroFab} alt='Picture of the author' />
							<Image className='position-absolute shield' src={HeroPop} alt='Picture of the author' />
							<Image className='shield' src={HeroRays} alt='Picture of the author' />
						</div>
					</div>
					<div id='mobile-shield' className='display-mobile-view banner-layer'>
						<div className='position-relative'>
							<Image className='position-absolute mobile-shield' src={MobileImg} alt='Picture of the author' />

							<Image className='position-absolute mobile-shield' src={MobileGroup} alt='Picture of the author' />
							<Image className='position-absolute  mobile-shield' src={MobileFab} alt='Picture of the author' />
							<Image className='position-absolute mobile-shield' src={MobilePopUp} alt='Picture of the author' />

							<Image className='mobile-shield' src={MobileRays} alt='Picture of the author' />
						</div>
					</div>
					<div className='display-mobile-view'>
						<Row className='position-relative g-0 mt-3'>
							<Col md={12} xs={12} className={`d-flex flex-column justify-content-around g-3 ${styles.companyIcon}`}>
								<div className='trusted-brands'>
									<span className='banner-italic-text'>
										Trusted by <b>2500+</b> SMEs & Enterprises
									</span>
								</div>
								<div className={`md-12 justify-content-around brand-gap scroll-tab ${styles.homeBannerBrand}`}>
									<Image src={GAIcon} alt='ga infra' />
									<Image src={JSLIcon} alt='jsl' />
									<Image src={DBLIcon} alt='dbl' />
									<Image src={TataIcon} alt='tata' />
								</div>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</Container>
	)
}

export default Banner
