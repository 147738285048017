'use client'

import React from 'react'
import Image from 'next/image'
import { Row, Col, Container } from 'react-bootstrap'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { HiArrowRight } from 'react-icons/hi2'
import BuyingImage from '@/assets/home/services/buying.webp'
import MetalRecyclingImage from '@/assets/home/services/metal-recycling.webp'
import FinanceImage from '@/assets/home/services/finance.webp'
import MetalProcessingImage from '@/assets/home/services/metal-processing.webp'
import { ReactGAEvent } from '@/components/ReactGAs'

const VideoPlayer = dynamic(() => import('@/components/VideoPlayer'), {
	ssr: false,
})

const YoutubeLinkId = 'FycK83GHFCw'

const ourServicesData = [
	{
		link: '/buying',
		_img: BuyingImage,
		title: 'Buying/Selling',
		description: 'Get connected to more than 4000+ stakeholders in the metal industry.',
	},
	{
		link: '/metal-fabrication',
		_img: MetalProcessingImage,
		title: 'Metal Fabrication',
		description: 'Get seamless metal fabrication and processing solutions.',
	},
	{
		link: '/finance',
		_img: FinanceImage,
		title: 'Finance',
		description: 'Tailor-made financing solutions for your ease of transaction.',
	},
	{
		link: '/recycling',
		_img: MetalRecyclingImage,
		title: 'Metal Recycling',
		description: 'Aiming to be the most efficient marketplace for secondary metals.',
	},
]

function Services() {
	const clickhandler = (category) => {
		ReactGAEvent({ action: 'our service click', event: 'our service', label: 'Our services', category })
	}
	return (
		<Container fluid>
			<Row className='service-container'>
				<div>
					<h1 className='text-center heading'>Our Services</h1>
					<p className='paragraph2'>We are a team of 100+ who are passionate about making the world a better place.</p>
				</div>
				<div className='service-content'>
					<Row>
						<Col xs={12} md={12} lg={6} className='p-2'>
							<div className='service-content-video-player'>
								<VideoPlayer videoId={YoutubeLinkId} />
							</div>
						</Col>
						<Col xs={12} md={12} lg={6}>
							<Row>
								{ourServicesData?.map((item, index) => (
									<Col xs={6} md={6} key={index} className='p-2 d-flex'>
										<Link href={item?.link} onClick={() => clickhandler(item?.title)}>
											<div className='card service-child-card'>
												<div className='in-1'>
													<div className='border-icon'>
														<Image src={item?._img} alt={item?.title} />
													</div>
													<HiArrowRight size='25' />
												</div>
												<div>
													<h2>{item?.title}</h2>
													<p>{item?.description}</p>
												</div>
											</div>
										</Link>
									</Col>
								))}
							</Row>
						</Col>
					</Row>
				</div>
			</Row>
		</Container>
	)
}

export default Services
