import React from 'react'

const Pill = ({ type, icon, text }) => {
	return (
		<div className={`d-flex col gap-2 align-items-center pill-wrapper pill-${type}`}>
			{icon && <div>{icon}</div>}
			<div>{text}</div>
		</div>
	)
}

export default Pill
