'use client'

import React from 'react'
// import { Col, Row } from "react-bootstrap";
import styles from '@/components/page.module.scss'
import Link from 'next/link'

function OfferBanner() {
	return (
		<div className='offer-banner-container'>
			<div className='offer-banner-inner'>
				<h1>Simplifying Recycling to promote a Sustainable Future and a robust Circular Economy</h1>
				<p className='card-description'>
					Metalbook's vision is to reduce dependency on raw material mining, and contribute in the reduction of global greenhouse gas emissions.
				</p>

				<div className='schedule-demo'>
					<Link href='https://calendly.com/metalbook/metalbook-live-demo' target='_blank' className={`btn border ${styles.scheduleDemo}`}>
						<p>Schedule a Demo</p>
					</Link>

					<Link className={`btn btn-primary border-0 ${styles.orderNow}`} href='/products'>
						<p>Order Now</p>
					</Link>
				</div>
			</div>
		</div>
	)
}

export default OfferBanner
