'use client'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Link from 'next/link'
import styles from './page.module.scss'
import { ReactGAEvent } from '@/components/ReactGAs'

function ReadyToOrder() {
	const clickhandler = (category) => {
		ReactGAEvent({ action: 'CTA Click', event: 'Schedule demo', label: 'CTA', category })
	}
	return (
		<Container fluid='lx'>
			<div className='ready-to-order-container'>
				<div className='d-flex flex-sm-column flex-column flex-lg-row justify-content-between align-items-center'>
					<Col xs={12} md={12} lg={7}>
						<h1>{`Ready to Order ?`} </h1>
						<p>{`Simplify your Metal sourcing and Fabrications with Metalbook. Order now and experience the difference for yourself ! `}</p>
					</Col>
					<Col xs={12} md={12} lg={5}>
						<Row>
							<Col className={`d-flex ${styles.ScheduleContainer}`}>
								<div>
									<Link
										href='https://calendly.com/metalbook/metalbook-live-demo'
										target='_blank'
										className={`btn border ${styles.scheduleDemo}`}
										onClick={() => {
											clickhandler('Schedule demo')
										}}
									>
										<p>Schedule a Demo</p>
									</Link>
								</div>
								<Link
									href={'/products'}
									onClick={() => {
										clickhandler('Order Now')
									}}
								>
									<div className={`btn btn-primary border-0 ${styles.scheduleDemo}`}>
										<p>Order Now</p>
									</div>
								</Link>
							</Col>
						</Row>
					</Col>
				</div>
			</div>
		</Container>
	)
}

export default ReadyToOrder
