'use client'

import React from 'react'
import { Row, Col, Container, Card } from 'react-bootstrap'
import { SlArrowDown } from 'react-icons/sl'
import Link from 'next/link'
import { ReactGAEvent } from '@/components/ReactGAs'

function CategorySection() {
	const items = [
		{
			id: 1,
			image: '/TMT_bar.webp',
			title: 'Ferrous',
			subtitle: 'Mild Steel, Stainless Steel',
			backgroundColor: '#E1D3FA',
			link: 'mild-steel',
		},
		{
			id: 2,
			image: '/copper.webp',
			title: 'Non-Ferrous',
			subtitle: 'Aluminium, Copper, Zinc, Nickel',
			backgroundColor: '#CCE8BE',
			link: 'aluminium',
		},
		{
			id: 3,
			image: '/aluminium.webp',
			title: 'Custom Manufacturing',
			subtitle: 'CNC Machining, Forging',
			backgroundColor: 'linear-gradient(180deg, #B6DFEC 0%, #BEEEFE 100%)',
			link: '',
		},
		{
			id: 4,
			image: '/scrap.webp',
			title: 'Metal Recycling',
			subtitle: 'Steel, Aluminium, Copper',
			backgroundColor: '#FFC7D1',
			link: 'ferrous-scrap',
		},
	]

	const viewMoreHandler = () => {
		ReactGAEvent({ action: 'View more click', event: 'View_more', label: 'Our categories', category: 'Categories' })
	}

	return (
		<>
			<Container fluid>
				<Row>
					<div className='category-container'>
						<h1 className='text-center heading'>Our Categories</h1>
						<Row className='g-3'>
							{items.map((item, i) => (
								<Col sm={6} xs={6} md={6} lg={3} key={item.id}>
									<Link href={`/products/${item?.link}`} className='cursor-hover '>
										<Card className='category-card card-hghlght' style={{ background: item.backgroundColor }}>
											<Card.Img variant='top' src={item.image} alt={item?.title} />
											<Card.Body className='px-2'>
												<h2 className='heading2'>{item?.title}</h2>
												<p className='paragraph'>{item?.subtitle}</p>
											</Card.Body>
										</Card>
									</Link>
								</Col>
							))}
						</Row>

						<div className='d-flex justify-content-center'>
							<button className='btn-view-more view-more border shadow-sm' onClick={viewMoreHandler}>
								<Link href={'../../products'}>
									View More <SlArrowDown size='18' />
								</Link>
							</button>
						</div>
					</div>
				</Row>
			</Container>
		</>
	)
}

export default CategorySection
