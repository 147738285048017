import(/* webpackMode: "eager" */ "/codebuild/output/src1127248754/src/mb-website/src/app/home/component/Banner.jsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1127248754/src/mb-website/src/app/home/component/CategorySection.jsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1127248754/src/mb-website/src/app/home/component/ChooseUs.jsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1127248754/src/mb-website/src/app/home/component/DownloadApp.jsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1127248754/src/mb-website/src/app/home/component/Industries.jsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1127248754/src/mb-website/src/app/home/component/MarketNews.jsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1127248754/src/mb-website/src/app/home/component/OfferBanner.jsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1127248754/src/mb-website/src/app/home/component/Partner.jsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1127248754/src/mb-website/src/app/home/component/People.jsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1127248754/src/mb-website/src/app/home/component/Services.jsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1127248754/src/mb-website/src/components/ReadyToOrder.jsx")