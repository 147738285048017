const english = {
	metalbook: 'Metalbook',

	add_item: 'Add item',

	add_new_address: 'Add a new address',
	trusted_by_brands: 'Trusted by 2500+ clients',
	home_banner_heading: `India's Largest Managed Marketplace for`,
	home_banner_sub_heading: 'Building Stronger & Sustainable Metal Supply Chain with Industry 4.0',
}

export default english
