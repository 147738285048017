'use client'

import React, { useState } from 'react'
import Image from 'next/image'
import { Row, Col, Card } from 'react-bootstrap'
import playCricle from '@/assets/icon/play-cricle.png'
import VideoModal from './VideoModal'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import image1 from '@/assets/home/ghv.webp'
import image2 from '@/assets/home/ms_agar.webp'
import image3 from '@/assets/home/srr.webp'
import image4 from '@/assets/home/apex.webp'
import image5 from '@/assets/home/jindal.webp'
import chandraImg from '@/assets/home/chandra-shekhar-mishra.webp'

// import required modules
import { Autoplay, FreeMode } from 'swiper/modules'

const testimonials = [
	{
		title: 'GM Procurement, GHV India',
		testimonial:
			'We had issues in the past with vendors delaying our material deliveries, which affected our planning and execution in the projects, but with Metalbook we are getting the right prices and material delivery which we can track on their app.',
		image: image1,
		name: 'Hari Teja Reddy',
	},
	{
		title: 'Cluster Head, MS Agarwal Foundries',
		testimonial:
			'Metalbook has significantly helped us to increase our sales and liquidate our inventory quicker. Our team uses the Metalbook app and desktop platform to quote prices and track our orders. It has made our life a lot easier. ',
		image: image2,
		name: 'Vikalp Saxena',
	},
	{
		title: 'Owner, Apex Structures',
		testimonial:
			'We started with procuring Steel for a project in a remote location in Assam in to assess their operation efficiency. Today they are our exclusive partner for material procurement owing to their excellent pricing & financing solutions.',
		image: image4,
		name: 'Khushi Agarwal',
	},
	{
		title: 'Regional Product Manager, JSPL',
		testimonial:
			'Being a part of Metalbooks global network has expanded our reach and business opportunities. The platform efficient system and seamless transactions have enabled us to connect with diverse buyers, making Metalbook an essential partner in our growth..',
		image: image5,
		name: 'Om Prakash',
	},
	{
		title: 'GM Purchase,SRR Projects',
		testimonial:
			'We have been working with Metalbook for more than an year & have been really impressed with their transparency & support. There were a couple of occasions where Metalbook went above and beyond to honour their commitments.',
		image: image3,
		name: 'KV Rao',
	},
]
function People() {
	const [showVideo, setShowVideo] = useState(false)
	return (
		<>
			<div className='bg-people-container'>
				<div className='people-container'>
					<div>
						<h1 className='text-center heading'>Testimonials</h1>
						<p className='paragraph2'>
							Discover what our valued partners and clients have to say about their seamless experiences with Metalbook.
						</p>
					</div>
					<div className='people-card'>
						<div
							className='position-relative cursor-pointer'
							onClick={() => {
								setShowVideo(true)
							}}
						>
							<Image src={chandraImg} width={250} height='140' alt='Picture of the author' className='rounded-3' />
							<Image src={playCricle} className='vedio-icon' alt='video icon' />
							{/* <SiYoutube size='40' fill='	#FF0000' className='vedio-icon' /> */}
						</div>

						<div className='inner-card'>
							<p className='card-description'>
								“Absolutely stellar experience !! From start to finish, Metalbook service exceeded all our expectations and the
								product delivered was exactly according to our specifications.”
							</p>
							<div>
								<span className='people-card-title'>Chandra Shekhar Mishra</span> <br />
								<span className='card-sub-title'>Sr. Manager, Bygging India</span>
							</div>
						</div>
					</div>
				</div>

				<div className='d-flex gap-4 justify-content-center people-container-swiper'>
					<Swiper
						spaceBetween={25}
						slidesOffsetAfter={0}
						pagination={{
							clickable: true,
						}}
						updateOnWindowResize={true}
						autoplay={{
							delay: 1,
							disableOnInteraction: false,
						}}
						speed={6000}
						loop={true}
						breakpoints={{
							0: {
								slidesPerView: 1,
							},
							400: {
								slidesPerView: 1,
							},
							639: {
								slidesPerView: 1,
							},
							865: {
								slidesPerView: 3,
							},
							1000: {
								slidesPerView: 3,
							},
						}}
						modules={[Autoplay, FreeMode]}
						className='mySwiper'
					>
						<Row>
							{testimonials.map((item, i) => (
								<SwiperSlide key={`${i}-people`}>
									<Card className='h-100 testimonial-card border-0'>
										<Card.Header>
											<Row>
												<Col xs={12} md={3} lg={3}>
													<Image src={item.image} width={64} height={64} alt='Picture of the author' />
												</Col>
												<Col xs={12} md={9} lg={9}>
													<span className='people-card-title'>{item?.name}</span> <br />
													<span className='card-sub-title'>{item?.title}</span>
												</Col>
											</Row>
										</Card.Header>
										<Card.Body>
											<p className='card-description'>“{item.testimonial}”</p>
										</Card.Body>
									</Card>
								</SwiperSlide>
							))}
						</Row>
					</Swiper>
				</div>
				{showVideo && <VideoModal showVideo={showVideo} setShowVideo={setShowVideo} />}
			</div>
		</>
	)
}

export default People
