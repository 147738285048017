const getUrl = {
	getCategoryBySlug: '/category/find',
	fetchCategoryDetails: '/category/fetchCategoryDetail',
	downloadFile: 'files/loadDocument',
	getAllCategoryProducts: '/category/getAll',
	getAllBlogs: '/content/blog/all', //
	getAllCommodityPrices: '/commodity-price/getLatestPrices',
	getRelatedContent: '/content/getRelatedContent',
	getAllContent: '/content/all',
	getContentBySlug: 'content/getContentBySlug',
	getAllGlossarySummary: '/glossary/getAllGlossarySummary',
	getGlossaryBySlug: '/glossary/getBySlug',
	getLatestPrices: 'commodity-price/v2/getLatestPrices',
}

export default getUrl
