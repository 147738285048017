'use client'

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import styles from '../page.module.scss'

const chooseUsData = [
	{
		title: 'One-Stop Solution',
		description:
			'Metalbook covers the entire spectrum of the Metal supply chain with tech-driven solutions ranging from Fulfillment to Cloud Manufacturing, Financing Solutions, Quality control and Last mile delivery.',
	},
	{
		title: 'Customization Services',
		description:
			'We convert your complex requirements into finished products using our network of advanced Cloud manufacturing partners, ensuring efficiency, precision, and quality throughout the entire production process.',
	},
	{
		title: 'Discovery',
		description:
			'Get access to our extensive network of 1500+ suppliers across the globe and get quotations on demand from our verified suppliers.',
	},
	{
		title: 'Financing',
		description:
			'We offer reliable and flexible financing solutions that cater to your dynamic needs, helping your business grow and prosper without any working capital challenges. Access our network of 10+ FI partners to help you with flexible financing solutions.',
	},
]

const ChooseUs = () => {
	const YoutubeLinkId = '4hdoDsTj204'

	const isIphone = () => {
		if (typeof window !== 'undefined') return navigator.userAgent?.includes('iPhone')
	}

	return (
		<Container fluid>
			<div className='choose-us-container'>
				<Container>
					<Row className='choose-action'>
						<Col xs={12} lg={6} md={12}>
							<div className='choose-wapper'>
								<div>
									<h1 className='heading'>Why Choose Us</h1>
									<p className='choose-sub-heading'>
										{`In the world of availability and quality issues, high prices and delayed deliveries, Metalbook is revolutionizing the Metal supply chain by bringing in their proprietary digital solutions.`}
									</p>
								</div>
								{chooseUsData?.map((item, index) => (
									<div className={`rounded-3 p-2 ${styles.activeBox}`} key={index}>
										<h2 className='choose-title'>{item?.title}</h2>
										<p className='choose-sub-title'>{item?.description}</p>
									</div>
								))}
							</div>
						</Col>
						{!isIphone() && (
							<Col xs={12} lg={6} md={12} className='d-flex'>
								<video preload='auto' loop autoPlay playsInline controls className='fabrication'>
									<source src='/videos/fabrication.webm' type='video/mp4' />
									<track src='/path/to/captions.vtt' kind='subtitles' srcLang='en' label='English' />
									Your browser does not support the video tag.
								</video>
							</Col>
						)}
					</Row>
				</Container>
			</div>
		</Container>
	)
}

export default ChooseUs
