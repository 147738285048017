const bengali = {
  metalbook: "মেটালবুক",
  //// home
  all: "সব",
  category: "বিভাগ",
  our: "আমাদের",
  home_banner_sub_heading: "Lorem ipsum dolor sit amet, consectetur BN",
  
};
export default bengali;
