import getUrl from "../data/conn/get/paths";
export const getFileUrl = `${process.env.serverUrl}${getUrl.downloadFile}?filePath=`;


export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const decimalSeparatedNumber = (number, options = {}) => {
	const _amount = parseFloat(number)

	if (isNaN(_amount)) return options.default ?? '-'

	return _amount.toLocaleString('en-IN', options)
}
export const displayAmount = (amount, options) => {
	return decimalSeparatedNumber(amount, {
		currency: 'INR',
		style: 'currency',
		...options,
	})
}