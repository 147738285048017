'use client'

import React from 'react'
import Image from 'next/image'
import { Col, Container, Row } from 'react-bootstrap'
import InfraConstruction from '@/assets/home/industries/1.webp'
import Machinery from '@/assets/home/industries/machinery.webp'
import AutoTransport from '@/assets/home/industries/auto-transport.webp'
import Renew from '@/assets/home/industries/renew.webp'
import DefenseAerospace from '@/assets/home/industries/defense_aerospace.webp'
import OilGas from '@/assets/home/industries/oil_gas_new.webp'
import ConsumerDurables from '@/assets/home/industries/consumer_durables.webp'
import ElectricVehicles from '@/assets/home/industries/electric_vehicles.webp'

const indusrtiesData = [
	{
		img: InfraConstruction,
		title: 'Infra & Construction',
	},
	{
		img: AutoTransport,
		title: 'Automotive & Transportation',
	},
	{
		img: Machinery,
		title: 'Machinery & Equipment',
	},
	{
		img: Renew,
		title: 'Renewable Energy',
	},
	{
		img: DefenseAerospace,
		title: 'Defense & Aerospace',
	},
	{
		img: OilGas,
		title: 'Oil & Gas',
	},
	{
		img: ConsumerDurables,
		title: 'Consumer Durables',
	},
	{
		img: ElectricVehicles,
		title: 'Electric Vehicles',
	},
]

const Industries = () => {
	return (
		<Container className='industries-container'>
			<Row className='industries-home-container'>
				<Col>
					<div className='d-flex flex-column align-items-center text-center'>
						<h2 className='heading'>Industries we serve</h2>
						<p className='paragraph2'>
							We serve and support diverse industries by delivering innovative solutions that enhance efficiency, quality, and
							scalability throughout their supply chains, ensuring optimal performance and competitive advantage.{' '}
						</p>
					</div>
				</Col>
			</Row>
			<Row className='xs-industries-home'>
				{indusrtiesData?.map((item, index) => (
					<Col xs={12} md={6} lg={3} key={`industry-${index}`} className='justify-content-center content-hghlght'>
						<div className='card mb-3 xs-card card-hghlght'>
							<Image src={item?.img} className='card-img-top' alt='...' />
							<div className='card-body p-1 w-100 justify-content-center'>
								<h5 className='card-title text-center'>{item?.title}</h5>
								{/* <p className='card-text'>{item?.description}</p> */}
							</div>
						</div>
					</Col>
				))}
			</Row>
		</Container>
	)
}

export default Industries
