'use client'

import React from 'react'
import { Row, Col } from 'react-bootstrap'

function Partner() {
	const data = [
		{
			count: '1500+',
			title: 'Global suppliers',
			description: 'Access global network of 1500+ suppliers',
		},
		{
			count: '1000+',
			title: 'Total SKUs served',
			description: 'Order from range of 1000+ SKUs',
		},
		{
			count: '350k+ MT',
			title: 'Material Delivered',
			description: 'Successfully delivered 350k+ MT till date',
		},
		{
			count: '10+',
			title: 'Lending Partners',
			description: 'Onboarded 10+ lending partners',
		},
		{
			count: '60+',
			title: 'Logistics Partners',
			description: 'Extensive network of 60+ 3PL Partners',
		},
		{
			count: '2500+',
			title: 'SMEs & Enterprises powered',
			description: 'Empowered 2500+ businesses till date',
		},
	]
	return (
		<div className='partner-container'>
			<Row className='partner-heading'>
				<Col className='d-flex align-items-center flex-column'>
					<h1 className='text1'>Partner With Us To Enjoy</h1>
					<p className='text2 text-center'>
						Find the best deals worldwide from trusted suppliers. Our network makes buying and selling simpler, with trusted logistical
						operations and attractive financing deals.
					</p>
				</Col>
			</Row>

			<Row className='g-3'>
				{data?.map((item, i) => (
					<Col key={i} xs={6} md={6} lg={4} className='d-flex'>
						<div className='partner-card-container'>
							<h2 className='partner-count'>{item?.count}</h2>
							<h3 className='text-inner-1'>{item?.title}</h3>
							<p className='text-inner-2'>{item?.description}</p>
						</div>
					</Col>
				))}
			</Row>
		</div>
	)
}

export default Partner
