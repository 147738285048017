'use client'

import React from 'react'
import { Row, Col } from 'react-bootstrap'
import AppleImage from '@/assets/download-app/apple.webp'
import GoogleImage from '@/assets/download-app/google.webp'
import Image from 'next/image'

function DownloadApp() {
	return (
		<div className='download-section-container'>
			<div className='download-section-inner'>
				<Row>
					<Col xs={12} md={12} lg={6} className='p-relative'>
						<div className='download-image-container'>
							<img className='' src='/mobile_mac.webp' alt='' />
						</div>
						<div className='download-image-container1'>
							<img className='' src='/15pro.webp' alt='' />
						</div>
					</Col>
					<Col xs={12} md={12} lg={6}>
						<div className='text-area-download'>
							<h2 className='download-title'>Download our App</h2>

							<p className='download-subtitle'>
								Improve your Fulfillment, Customizations services and Financing Solutions on your fingertip.Enjoy seamless experience
								on our app.
							</p>

							<div className='d-flex download-link-store'>
								<a href='https://apps.apple.com/in/app/metalbook/id6450207151' target='_blank'>
									<Image src={AppleImage} alt='appstore' />
								</a>
								<a href='https://play.google.com/store/apps/details?id=app.metalbook' target='_blank'>
									<Image src={GoogleImage} alt='play store' />
								</a>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default DownloadApp
