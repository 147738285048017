import React, { useEffect, useRef, useState } from 'react'
import { Form, InputGroup, Toast } from 'react-bootstrap'
import { GiMetalBar } from 'react-icons/gi'
import { useRouter } from 'next/navigation'
import Image from 'next/image'
import SearchIcon from '@/assets/home/search-icon.svg'
import Pill from '@/components/base/Pill'
import { ReactGAEvent } from '@/components/ReactGAs'

const Search = ({ data }) => {
	const [searchTerm, setSearchTerm] = useState('')
	const [searchResults, setSearchResults] = useState([])
	const [allProducts, setAllProducts] = useState([])
	const [selectedItem, setSelectedItem] = useState(-1)
	const [focused, setFocused] = useState(false)
	const selectRef = useRef()
	const router = useRouter()
	const [show, setShow] = useState(false)
	const FerrousItems = [
		{
			title: 'TMT Bars',
			key: 'ms-tmt-bars',
			icon: <GiMetalBar />,
		},
		{
			title: 'Aluminium Billets',
			key: 'aluminium-billets',
			icon: <GiMetalBar />,
		},
		{
			title: 'MS Scrap',
			key: 'mild-steel-scrap',
			icon: <GiMetalBar />,
		},
		{
			title: 'CR Coil',
			key: 'cr-coils',
			icon: <GiMetalBar />,
		},
	]

	const clickGAEvent = () => {
		ReactGAEvent({ action: 'Search bar click', event: 'Search_bar', label: '', category: 'Select Product' })
	}

	useEffect(() => {
		setAllProducts(data)
		setSearchResults(data)
	}, [])
	const setChange = () => {
		const selected = selectRef?.current?.querySelector('.highlight-dropdown-item')
		if (selected) {
			selected?.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
			})
		}
	}
	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value)
		let filteredResults = allProducts.filter((item) => item?.category_name?.toLowerCase().includes(e.target.value.toLowerCase()))
		setSearchResults(filteredResults)
		if (!filteredResults?.length) {
			setSearchResults(allProducts)
			setShow(!filteredResults?.length)
		} else {
			setShow(false)
		}
	}
	const searchHandler = (id) => {
		if (id === null) return

		const slugid = id
		clickGAEvent()
		router.push(`/product-details/${slugid}`)
	}
	const pillClickHandler = (slug) => {
		router.push(`/product-details/${slug}`)
	}
	const onKeyDownHandler = (e) => {
		if (e.keyCode === 40) {
			setSelectedItem((selectedItem + 1) % searchResults.length)
		} else if (e.keyCode === 38) {
			setSelectedItem(selectedItem === 0 ? selectedItem : (selectedItem - 1) % searchResults.length)
		} else if (e.keyCode === 13) {
			if (selectedItem === -1) return
			searchHandler(searchResults[selectedItem].slug)
		}
	}
	return (
		<>
			<div className='w-100'>
				<div className=''>
					<InputGroup className={` ${searchTerm ? 'search-input-wrapper-open' : 'search-input-wrapper'}`}>
						<Form.Control
							tabIndex='0'
							placeholder='Explore Products'
							type='text'
							className='dropdown-toggle search-input  '
							value={searchTerm}
							onChange={handleSearchChange}
							onKeyDown={onKeyDownHandler}
							onFocus={() => setFocused(true)}
							onBlur={() => {
								ReactGAEvent({ action: 'Search bar click', event: 'Search_bar', label: 'Blank', category: 'select product' })
								setFocused(false)
							}}
						/>
						<InputGroup.Text className='cursor-pointer'>
							<Image width={20} height={20} src={SearchIcon} alt='..' />
						</InputGroup.Text>
					</InputGroup>
					{focused && (
						<div className='search-dropdown-wrapper'>
							<div className='dropdown-menu show search-dropdown' ref={selectRef}>
								{searchResults.map((item, i) => {
									let _class = ''
									if (i === selectedItem) {
										_class = 'highlight-dropdown-item'
									}
									setTimeout(() => {
										setChange()
									}, [100])
									return (
										<div
											onMouseDown={() => searchHandler(item.slug)}
											onMouseOver={() => setSelectedItem(i)}
											key={i}
											className={`search-dropdown-item ${_class}`}
										>
											{item.category_name}
										</div>
									)
								})}
							</div>
						</div>
					)}
				</div>
				{!searchTerm && (
					<div>
						<div className='d-flex col flex-wrap gap-2 justify-content-left w-100'>
							{FerrousItems.map((item, index) => {
								return (
									<div key={index} className='search-pill-wrapper cursor-pointer' onMouseDown={() => pillClickHandler(item.key)}>
										<Pill type='quaternary' text={item.title} />
									</div>
								)
							})}
						</div>
					</div>
				)}
			</div>
			<Toast className='search-toast' onClose={() => setShow(false)} data-testid='toast' show={show} delay={10000} autohide>
				<Toast.Header closeVariant='white'>
					<small className='me-auto'>Item not found. Please select from the options</small>
				</Toast.Header>
			</Toast>
		</>
	)
}
export default Search
